
.dropdown-button {
  cursor: pointer;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.dropdown-button:hover {
  background: var(--action-border-color);
}
.dropdown-button__icon {
  flex-grow: 0;
  flex-shrink: 0;

  width: 14px;
  height: 14px;

  transform: rotate(-90deg);

  transition: 150ms ease-in-out;
}
.dropdown-button_active .dropdown-button__icon {
  transform: rotate(0);
}

