
.columns-select-group {
  --font-color: var(--text-main-color);

  color: var(--font-color);
}
.columns-select-group__header {
  font-size: 16px;
  font-weight: var(--medium-font-weight);

  display: flex;

  flex-direction: row;

  margin-top: 15px;
}
.columns-select-group__title {
  cursor: pointer;

  display: flex;

  align-items: flex-start;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}
.columns-select-group__icon {
  flex-grow: 0;
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  margin-right: 4px;
}
.columns-select-group__group-name {
  text-overflow: ellipsis;

  overflow: hidden;

  /* webkit-box not working in firefox, but in this case it is ok, it will not break layout */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */

  /* stylelint-disable property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable property-no-vendor-prefix */
}
.columns-select-group__items-list {
  margin-left: 8px;
}

/* apply style for second and next elements */
.columns-select-group__items-list > .columns-select-group-list__item ~ .columns-select-group-list__item {
  margin-top: 12px;
}
.columns-select-group__dropdown-trigger {
  flex-grow: 0;
  flex-shrink: 0;

  margin-right: 4px;
}

