
.m-table-header {
  --bottom-shadow-offset: 0;

  font-weight: var(--medium-font-weight);

  vertical-align: bottom;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 16;

  height: 100%;

  background: var(--background-color);
}
.m-table-header__help {
  margin: 0 4px;
}
.m-table-header__content {
  color: var(--text-main-color);

  display: flex;

  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  width: 100%;

  padding: 12px 8px;
}
.m-table-header_is-first .m-table-header__content {
  padding-left: 14px;
}
.m-table-header_is-last .m-table-header__content {
  padding-right: 14px;
}
.m-table-header_sortable {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.m-table-header_sortable-disabled {
  cursor: unset;
}
.m-table__body th.m-table-header_superheader {
  height: 34px;
}
.m-table-header_superheader .m-table-header__content {
  padding-bottom: 4px;
}
.m-table-header_subheader .m-table-header__content {
  padding-top: 4px;
}
.m-table-header_subheader {
  top: 34px;
}
.m-table-header__content-wrapper {
  position: relative;
  display: flex;

  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-end;

  width: var(--current-cell-width);
  height: var(--cell-height);
}
.m-table-header_sticky-column {
  left: var(--sticky-left-offset);
  z-index: 20;
}
.m-table-header_sticky-column::after {
  content: '';

  position: absolute;
  top: 0;
  right: -30px;

  width: 30px;
  height: 100%;

  box-shadow: inset 31px 0 18px -20px var(--sticky-cell-vertical-shadow-color);

  opacity: 0;

  transition: opacity 100ms;

  pointer-events: none;
}
.m-table-header__sticky-trigger {
  position: absolute;
  bottom: 0;
  left: calc(var(--sticky-left-offset) * -1);
}
.m-table-header_with-right-border {
  box-sizing: border-box;
  border-right: 1px solid var(--border-color);
}
.m-table-header_bottom-header .m-table-header__content-wrapper {
  border-bottom: 1px solid var(--border-color);
}
.m-table_sticky .m-table-header_bottom-header .m-table-header__content-wrapper {
  border-bottom-color: var(--background-color);
}
.m-table_sticky .m-table-header_bottom-header .m-table-header__content-wrapper::after {
  content: '';

  position: absolute;
  bottom: -40px;
  left: 0;
  z-index: -5;

  width: 100%;
  height: 40px;

  box-shadow: inset 0 11px 12px -5px var(--sticky-header-cell-shadow-color);

  opacity: 0;

  transition: opacity 100ms;

  pointer-events: none;
}
.m-table-header_column-sticked::after,
.m-table_sticky .m-table-header_bottom-header .m-table-header__content-wrapper::after {
  opacity: 1;
}
.m-table-header_column-sticked.m-table-header_with-right-border {
  border-right-color: var(--background-color);
}
.m-table-header_with-right-border.m-table-header_bottom-header .m-table-header__content-wrapper::after {
  /* deal with border */
  width: calc(100% + 1px);
}
.m-table-header_left .m-table-header__content,
.m-table-header_right .m-table-header__content,
.m-table-header_center .m-table-header__content {
  align-items: center;
}
.m-table-header_left .m-table-header__content {
  text-align: left;
  justify-content: start;
}
.m-table-header_right .m-table-header__content {
  text-align: right;
  justify-content: end;
}
.m-table-header_center .m-table-header__content {
  text-align: center;
  justify-content: center;
}
.m-table-header_sortable.m-table-header_right .m-table-header__content {
  padding-right: 0;
}
.m-table-header_is-last.m-table-header_sortable.m-table-header_right .m-table-header__content,
.m-table-header_sortable.m-table-header_right.m-table-header_with-right-border .m-table-header__content {
  padding-right: 8px;
}
.m-table-header-content {
  align-self: end;
  min-width: 0;
}
.m-table-header_resizing {
  cursor: ew-resize;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.m-table-header-resize-node {
  cursor: ew-resize;

  position: absolute;
  display: none;
  top: 0;
  right: 0;

  height: 100%;

  padding-left: 3px;
}
.m-table-header-resize-node_active,
.m-table-header:hover .m-table-header-resize-node {
  background: var(--action-secondary-color);
}
.m-table-header:hover .m-table-header-resize-node__indicator {
  background-color: var(--resize-node-color);
}
.m-table-header-resize-node__subheader {
  height: calc(34px + 100%);
  margin-top: -35px;
}
.m-table-header-content-back-arrow {
  color: var(--action-color);

  cursor: pointer;

  width: 16px;
  height: 16px;
}
.m-table-header-content-back-arrow:hover {
  color: var(--action-hover-color);
  cursor: pointer;
}

