
.multi-select-placeholder {
  color: var(--gray-text-color);
  text-overflow: ellipsis;

  overflow: hidden;
}
.multi-select-value {
  display: flex;
  align-items: center;
}
.multi-select-value__icon {
  flex-grow: 0;
  flex-shrink: 0;

  width: 14px;
  height: 14px;

  margin-right: 8px;
}
.multi-select-value__text {
  color: var(--secondary-text-color);
  text-overflow: ellipsis;

  overflow: hidden;
}

