
.m-table-header-default {
  font-weight: var(--medium-font-weight);

  overflow: hidden;

  position: relative;

  width: 100%;
  max-height: 42px;
}
.m-table-header_superheader .m-table-header-default {
  font-weight: var(--main-font-weight);

  color: var(--panel-text-color);

  display: flex;

  justify-content: center;
  align-items: center;
}
.m-table-header-default_medium-font {
  max-height: 64px;
}
.m-table-header-default__shadow {
  position: absolute;
  top: 0;
  right: 0;

  height: 100%;

  box-shadow: 0 0 8px 8px var(--background-color);
}
.m-table-header-default__icon {
  flex-grow: 0;
  flex-shrink: 0;

  width: 12px;
  height: 12px;

  margin-right: 2px;
}
.m-table-header-default__help {
  display: inline;
}
