
.select-active__value[data-v-2e9373ab] {
  color: var(--secondary-text-color);
  text-overflow: ellipsis;

  overflow: hidden;

  display: flex;

  flex-direction: row;
  align-items: center;
}
.select-active__text-main[data-v-2e9373ab] {
  /* padding to prevent cutting of italic symbols */
  padding-right: 2px;
}
.select-active__text[data-v-2e9373ab] {
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;
}
.select-active__icon[data-v-2e9373ab] {
  flex-shrink: 0;

  height: 14px;

  margin-right: 6px;
}
.select-active__value_disabled[data-v-2e9373ab] {
  color: var(--gray-text-color)
}
.select-active__value_disabled .select-active__icon[data-v-2e9373ab] {
    opacity: 0.5;
}
.select-active__value_large-with-icon .select-active__icon[data-v-2e9373ab] {
    width: 32px;
    height: 32px;

    margin-right: 12px;
}
.select-active__value_large-with-icon:not(.select-active__value_placeholder-only-name) .select-active__text[data-v-2e9373ab] {
    font-size: 14px;
    font-weight: 500;

    color: #242424;
    line-height: 16px;
}
.select-active__value_large-with-icon .select-active__text-main[data-v-2e9373ab] {
    display: flex;
    align-items: baseline;
}
.select-active__value_large-with-icon .select-active__description[data-v-2e9373ab] {
    font-size: 12px;
    font-weight: 400;

    color: #696969;
    line-height: 14px;

    margin-top: 4px;
}
.select-active__value_large-with-icon .select-active__label[data-v-2e9373ab] {
    font-size: 10px;
    font-weight: 500;

    line-height: 11px;

    margin-left: 4px;

    background: linear-gradient(235.1deg, #4965FB -3.88%, #FF3D67 99.79%);
    -webkit-background-clip: text;
            background-clip: text;
    -webkit-text-fill-color: transparent;
}
