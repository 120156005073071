
.dropdown-menu__trigger {
  cursor: pointer;

  display: inline-flex;

  align-items: center;

  height: 18px;
}
.dropdown-menu__icon {
  color: var(--action-color);

  width: 18px;
  height: 18px;
}
.dropdown-menu__icon-arrow {
  width: 14px;
  height: 14px;

  transform: rotate(90deg);
}
.dropdown-menu__icon-arrow_active {
  transform: rotate(270deg);
}
.dropdown-menu__trigger_disabled .dropdown-menu__icon {
  color: var(--action-disabled-color);
  cursor: not-allowed;
}
.dropdown-menu__trigger:hover .dropdown-menu__icon {
  color: var(--action-hover-color);
}
.dropdown-menu__trigger_disabled:hover .dropdown-menu__icon {
  color: var(--action-disabled-color);
}

