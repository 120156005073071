
.selected-items-block__summary {
  font-size: 14px;
  font-weight: var(--medium-font-weight);

  color: var(--secondary-text-color);
}
.selected-items-block__reset {
  padding-top: 12px;
}
.selected-items-block__list {
  overflow-y: auto;
  margin-top: 2px;
}
.selected-items-block-list__item {
  margin-top: 6px;
}
