
.popper {
  --popover-arrow-size: 9px;
  --popover-arrow-offset: calc(calc(var(--popover-arrow-size) * -0.70710678118) + 0.5px);
  --popover-shading-z-index: 1000;
  --popper-box-shadow: 0px 3px 35px rgba(48, 56, 71, 0.3);

  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);

  color: var(--text-color);
  line-height: var(--main-line-height);

  position: absolute;

  /*
    Fixes jumping scroll
    when popper element is created it adds to popper element to the end of page (end of doom)
    this hack places popper element on top of page
  */
  top: -1000px;
  left: 0;
  z-index: var(--popover-z-index);

  box-sizing: border-box;

  background-color: var(--background-color);
  box-shadow: var(--popper-box-shadow);

  border-radius: var(--border-radius)
}
.popper.popper_with-arrow {
    margin: var(--popover-arrow-size)
}
.popper.popper_with-arrow .popper__arrow {
      position: absolute;

      box-sizing: border-box;
      width: calc(var(--popover-arrow-size) * 1.41421356237);
      height: calc(var(--popover-arrow-size) * 1.41421356237);

      background-color: var(--background-color);
      box-shadow: var(--popper-box-shadow);
}
.popper.popper_with-arrow[x-placement^='top'] .popper__arrow {
      bottom: var(--popover-arrow-offset);
      left: calc(50% - var(--popover-arrow-size));

      transform: rotate(-45deg);
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%);
              clip-path: polygon(0 0, 0 100%, 100% 100%);
}
.popper.popper_with-arrow[x-placement^='bottom'] .popper__arrow {
      top: var(--popover-arrow-offset);
      left: 50%;

      transform: rotate(45deg);
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
              clip-path: polygon(0 0, 0 100%, 100% 0);
}
.popper.popper_with-arrow[x-placement^='right'] .popper__arrow {
      top: 50%;
      left: var(--popover-arrow-offset);

      transform: rotate(-45deg);
      -webkit-clip-path: polygon(0 0, 0 100%, 100% 0);
              clip-path: polygon(0 0, 0 100%, 100% 0);
}
.popper.popper_with-arrow[x-placement^='left'] .popper__arrow {
      top: 50%;
      right: var(--popover-arrow-offset);

      transform: rotate(45deg);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.popper__shading-area {
  position: absolute;
  top: 0;
  z-index: var(--popover-shading-z-index);

  background-color: var(--shading-color)
}
.popper__shading-area:active,
  .popper__shading-area:focus {
    outline: none;
}
.popper__in-modal {
  z-index: 1101;
}
.popper-fade-leave-to,
.popper-fade-enter {
  opacity: 0;
}
.popper-fade-enter-active,
.popper-fade-leave-active {
  transition: opacity var(--transition-duration) ease-in-out;
}
