
.noty_layout_mixin, #noty_layout__centerRight, #noty_layout__centerLeft, #noty_layout__center, #noty_layout__bottomRight, #noty_layout__bottomCenter, #noty_layout__bottomLeft, #noty_layout__bottom, #noty_layout__topRight, #noty_layout__topCenter, #noty_layout__topLeft, #noty_layout__top {
    position: fixed;
    margin: 0;
    padding: 0;
    z-index: 9999999;
    transform: translateZ(0) scale(1, 1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur(0);
    -webkit-filter: blur(0);
    max-width: 90%;
}

#noty_layout__top {
    top: 0;
    left: 5%;
    width: 90%;
}

#noty_layout__topLeft {
    top: 20px;
    left: 20px;
    width: 325px;
}

#noty_layout__topCenter {
    top: 5%;
    left: 50%;
    width: 325px;
    transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__topRight {
    top: 20px;
    right: 20px;
    width: 325px;
}

#noty_layout__bottom {
    bottom: 0;
    left: 5%;
    width: 90%;
}

#noty_layout__bottomLeft {
    bottom: 20px;
    left: 20px;
    width: 325px;
}

#noty_layout__bottomCenter {
    bottom: 5%;
    left: 50%;
    width: 325px;
    transform: translate(calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__bottomRight {
    bottom: 20px;
    right: 20px;
    width: 325px;
}

#noty_layout__center {
    top: 50%;
    left: 50%;
    width: 325px;
    transform: translate(calc(-50% - .5px), calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerLeft {
    top: 50%;
    left: 20px;
    width: 325px;
    transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

#noty_layout__centerRight {
    top: 50%;
    right: 20px;
    width: 325px;
    transform: translate(0, calc(-50% - .5px)) translateZ(0) scale(1, 1);
}

.noty_progressbar {
    display: none;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #646464;
    opacity: 0.2;
    filter: alpha(opacity=10);
}

.noty_bar {
    -webkit-backface-visibility: hidden;
    transform: translate(0, 0) scale(1, 1);
    -webkit-font-smoothing: subpixel-antialiased;

    margin: 12px 0;
}

.noty_effects_open {
    opacity: 0;
    transform: translate(50%);
    -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.noty_effects_close {
    -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.noty_fix_effects_height {
    -webkit-animation: noty_anim_height 75ms ease-out;
            animation: noty_anim_height 75ms ease-out;
}

.noty_close_with_click {
    cursor: pointer;
}

.noty_close_button {
    position: absolute;
    top: 2px;
    right: 2px;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.noty_close_button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.noty_modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 10000;
    opacity: 0.3;
    left: 0;
    top: 0;
}

.noty_modal.noty_modal_open {
    opacity: 0;
    -webkit-animation: noty_modal_in 0.3s ease-out;
            animation: noty_modal_in 0.3s ease-out;
}

.noty_modal.noty_modal_close {
    -webkit-animation: noty_modal_out 0.3s ease-out;
            animation: noty_modal_out 0.3s ease-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes noty_modal_in {
    100% {
        opacity: 0.3;
    }
}

@keyframes noty_modal_in {
    100% {
        opacity: 0.3;
    }
}

@-webkit-keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@keyframes noty_modal_out {
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes noty_anim_in {
    100% {
        transform: translate(0);
        opacity: 1;
    }
}

@keyframes noty_anim_in {
    100% {
        transform: translate(0);
        opacity: 1;
    }
}

@-webkit-keyframes noty_anim_out {
    100% {
        transform: translate(50%);
        opacity: 0;
    }
}

@keyframes noty_anim_out {
    100% {
        transform: translate(50%);
        opacity: 0;
    }
}

@-webkit-keyframes noty_anim_height {
    100% {
        height: 0;
    }
}

@keyframes noty_anim_height {
    100% {
        height: 0;
    }
}
