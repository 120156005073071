
.m-table-cell-spacer {
  background: var(--background-color);
}
.m-table__body td.m-table-cell-spacer {
  height: 8px;
}
.m-table-cell.m-table-cell-spacer {
  display: table-cell;
  padding: 0;
}
