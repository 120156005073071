
.tree-select-dropdown-selected-item {
  display: flex;

  align-items: center;

  width: 100%;

  background: var(--gray-table-color);

  border-radius: 5px;
}
.tree-select-dropdown-selected-item__delete {
  cursor: pointer;

  display: flex;

  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 100%;

  margin-right: 4px;
}
.tree-select-dropdown-selected-item__delete svg {
  color: var(--gray-text-color);

  width: 14px;
  height: 14px;
}
.tree-select-dropdown-selected-item__delete:hover svg {
  color: var(--text-main-color);
}
.tree-select-dropdown-selected-item__name {
  text-overflow: ellipsis;

  overflow: hidden;

  flex-grow: 1;

  width: 100%;

  padding: 6px 8px;
}
