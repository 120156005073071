
.tag-list__tag {
  color: var(--secondary-text-color);
  line-height: 24px;
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;
  cursor: pointer;

  list-style: none;

  display: inline-flex;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  height: 28px;

  padding-left: 12px;
  padding-right: 12px;

  margin: 0 14px 0 0;

  background-color: var(--background-color);

  border: solid 1px var(--border-color);

  border-radius: 30px;

  transition: all var(--transition-duration) ease-in-out;
}
.tag-list__tag:hover {
  color: var(--action-hover-color);
  border-color: var(--action-hover-color);
}
.tag-list__tag:first-child {
  margin-left: 0;
}
.tag-list__tag:last-child {
  margin-right: 0;
}
.tag-list__tag.tag-list__tag_disabled,
.tag-list__tag.tag-list__tag_disabled:hover {
  color: var(--border-color);

  cursor: not-allowed;

  border-color: var(--border-color);
}
.tag-list__tag.tag-list__tag_active {
  color: var(--action-color);
  border-color: var(--action-color);
}
.tag-list__tag.tag-list__tag_small {
  line-height: 20px;

  height: 24px;

  padding-left: 8px;
  padding-right: 8px;

  margin: 0 12px 0 0;
}
.tag-list__tag.tag-list__tag_gray {
  color: var(--secondary-text-color);

  background-color: var(--gray-table-color);

  border: none;
}
.tag-list__tag.tag-list__tag_gray:hover {
  background-color: var(--gray-color);
}
.tag-list__tag.tag-list__tag_gray.tag-list__tag_active {
  background-color: var(--secondary-button-color);
}
.tag-list__tag.tag-list__tag_gray.tag-list__tag_active:hover {
  color: var(--secondary-text-color);
  background-color: var(--secondary-button-hover-color);
}
.tag-list__tag .tag-list__tag-icon {
  height: 12px;
}
