
.tag-list {
  display: flex;

  flex-wrap: wrap;

  padding-left: 0;

  margin: 0;
}
