
.table-settings {
  font-size: 14px;
  font-weight: var(--medium-font-weight);
}
.table-settings__settings .m-link__icon {
  width: 16px;
  height: 16px;
}
.table-settings,
.table-settings__dropdown .popper__wrapper {
  display: flex;
  align-items: center;
}
.table-settings__dropdown {
  margin-left: 12px;
}
.table-settings__dropdown .popper__wrapper span {
  display: block;
  height: 18px;
}
.table-settings__separator {
  width: 1px;
  height: 16px;

  margin-left: 12px;

  border-left: solid 1px var(--border-color)
}
.table-settings__separator.before-separator {
    margin-right: 12px;
}
