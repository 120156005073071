
.m-radiobutton__label {
  color: var(--text-color);

  /* deal with application.css */
  line-height: var(--main-line-height);

  padding: 0;

  margin: 0;
  margin-right: 16px;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}
.m-radiobutton {
  /* constants. Change from the outside */
  --empty-color: var(--background-color);
  --default-color: var(--border-color);
  --checked-color: var(--action-color);
  --disabled-color: var(--gray-color);
  --disabled-checked: var(--action-disabled-color);
  --disabled-text-color: var(--gray-text-color);

  /* variables. Shouldn't be changed outside */
  --check-border-color: var(--gray-text-color);
  --check-background-color: var(--empty-color);
  --check-border-width: 1px;

  cursor: pointer;

  display: flex;

  align-items: center
}
.m-radiobutton:not(.m-radiobutton_checked):not(.m-radiobutton_disabled):hover {
    --check-border-color: var(--checked-color);
}
.m-radiobutton.m-radiobutton_disabled {
    --check-background-color: var(--disabled-color);
    --check-border-color: var(--disabled-color);
    --text-color: var(--disabled-text-color);

    cursor: not-allowed
}
.m-radiobutton.m-radiobutton_disabled .m-radiobutton__label {
      /* deal with application.css */
      cursor: not-allowed;
}
.m-radiobutton.m-radiobutton_checked {
    --check-border-width: 5px;
    --check-border-color: var(--checked-color);
}
.m-radiobutton.m-radiobutton_disabled.m-radiobutton_checked {
    --check-border-color: var(--disabled-checked);
    --check-background-color: var(--background-color);
}
.m-radiobutton .m-radiobutton__check {
    box-sizing: border-box;
    width: 14px;
    height: 14px;

    margin-right: 8px;

    background-color: var(--check-background-color);

    border: var(--check-border-width) solid var(--check-border-color);

    border-radius: 50%;

    -webkit-user-select: none;

       -moz-user-select: none;

            user-select: none;
}
.m-radiobutton__hidden {
  display: none;
}
