
.tree-select-dropdown-selected {
  overflow: hidden;

  width: 252px;
  height: 336px
}
.tree-select-dropdown-selected.notEmpty {
    overflow-x: hidden;
    overflow-y: auto;
}
.tree-select-dropdown-selected .m-notification__text {
    font-size: 12px;
}
.tree-select-dropdown-selected .m-notification__contents {
    padding: 10px;
}
.tree-select-dropdown-selected__items {
  padding: 2px 12px;
}
.tree-select-dropdown-selected__items .tree-select-dropdown-selected-item {
  margin-top: 6px;
}
.tree-select-dropdown-selected__summary {
  font-size: 14px;
  font-weight: var(--medium-font-weight);

  color: var(--text-main-color);

  padding: 20px 12px 12px;
}
.tree-select-dropdown-selected__reset_all {
  padding: 0 12px;
}
.tree-select-dropdown-reset-all {
  color: var(--panel-text-color);

  cursor: pointer;

  display: flex;

  align-items: center;
}
.tree-select-dropdown-reset-all:hover {
  color: var(--secondary-text-color);
}
.tree-select-dropdown-reset-all__cross {
  height: 14px;
  margin-right: 4px;
}
.tree-select-dropdown-reset-all__cross svg {
  width: 14px;
  height: 14px;
}
.notify-container {
  width: 230px;
  height: 70px;

  margin-top: 220px;
  margin-left: 10px;
}
