
.column-select-column-item .m-checkbox__label {
  white-space: normal;
  overflow: auto;
}
.column-select-column-item .m-checkbox {
  align-items: flex-start;
}
.column-select-column-item__parent {
  display: flex;
}
.column-select-column-item__child {
  margin-top: 12px;
  margin-left: 30px;
}
.column-select-column-item {
  margin-top: 12px;
}
.column-select-column-item__icon {
  flex-grow: 0;
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  margin-left: 8px;
}
.column-select-column-item__toggle-open {
  /* dirty think to make beautiful layout and not to break anything */
  position: relative;
  top: -1px;

  flex-grow: 0;
  flex-shrink: 0;

  margin-right: 4px;
}
.column-select-column-item__checkbox_no-children {
  margin-left: 20px;
}
.column-select-column-itme__toggle-oren_open {
  transform: rotate(90deg);
}
