
.columns-order {
  padding: 16px;
}
.columns-order__counter {
  font-size: 16px;
  font-weight: var(--medium-font-weight);

  color: var(--panel-text-color);
  line-height: 18px;
}
