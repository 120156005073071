
.m-checkbox__label {
  /* deal with application.css */
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);

  color: var(--text-color);
  line-height: unset;

  /* ellipsis settings */
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;

  cursor: pointer;

  padding: 0;

  margin: 0;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}
.m-checkbox__hidden {
  display: none;
}
.m-checkbox__icon {
  flex-shrink: 0;

  width: 12px;
  height: 12px;

  margin-right: 8px;
}
.m-checkbox {
  /* constants. Change from the outside */
  --empty-color: var(--background-color);
  --default-color: var(--border-color);
  --checked-color: var(--action-color);
  --disabled-color: var(--gray-color);
  --disabled-checked: var(--action-disabled-color);
  --disabled-text-color: var(--gray-text-color);

  /* variables. Shouldn't be changed outside */
  --checkmark-border-color: var(--gray-text-color);
  --checkmark-background-color: var(--empty-color);
  --checkmark-color: var(--checkmark-background-color);
  --label-text-color: var(--text-color);

  cursor: pointer;

  display: flex;

  align-items: center
}
.m-checkbox.select-option_focus,
  .m-checkbox:not(.m-checkbox_checked):not(.m-checkbox_disabled):hover {
    --checkmark-border-color: var(--checked-color);
}
.m-checkbox.m-checkbox_disabled {
    --checkmark-background-color: var(--disabled-color);
    --checkmark-border-color: var(--disabled-color);
    --text-color: var(--disabled-text-color);

    cursor: not-allowed
}
.m-checkbox.m-checkbox_disabled .m-checkbox__label {
      /* deal with application.css */
      cursor: not-allowed;
}
.m-checkbox.m-checkbox_checked {
    --checkmark-background-color: var(--checked-color);
    --checkmark-border-color: var(--checked-color);
    --checkmark-color: var(--empty-color);
}
.m-checkbox.m-checkbox_disabled.m-checkbox_checked {
    --checkmark-background-color: var(--disabled-checked);
    --checkmark-border-color: var(--disabled-checked);
}
.m-checkbox .m-checkbox__check-mark {
    display: inline-flex;

    flex-shrink: 0;

    box-sizing: border-box;
    width: 14px;
    height: 14px;

    margin-right: 8px;

    background-color: var(--checkmark-background-color);

    border: 1px solid var(--checkmark-border-color);

    border-radius: 2px;

    -webkit-user-select: none;

       -moz-user-select: none;

            user-select: none
}
.m-checkbox .m-checkbox__check-mark .m-checkbox__icon {
      color: var(--checkmark-color);

      width: 100%;
      height: 100%;
}
