
.selected-items-block-reset {
  font-size: 12px;

  color: var(--panel-text-color);

  cursor: pointer;

  display: flex;

  align-items: center;
}
.selected-items-block-reset:hover {
  color: var(--secondary-text-color);
}
.selected-items-block-reset__cross {
  width: 14px;
  height: 14px;

  margin-right: 4px;
}
