
.m-help {
  cursor: pointer;

  width: 14px;
  height: 14px;
}
.m-help__popper {
  z-index: 10000;
}
.m-help__content {
  font-size: 12px;

  color: var(--secondary-text-color);

  box-sizing: border-box;

  padding: 12px;
}
.m-help__icon {
  color: var(--panel-text-color);

  width: 14px;
  height: 14px;
}
.m-help__icon:hover,
.m-help__icon_active {
  color: var(--action-color);
}
