
.calendar {
  --calendar-day-size: 22px;

  font-family: var(--main-font-family);
  font-size: var(--secondary-font-size);
  font-weight: var(--main-font-weight);

  color: var(--text-color);

  width: 192px;

  background-color: var(--background-color);

  border-spacing: 0 4px;
  border-collapse: separate;
}

/* reset application.css */
.calendar__body th {
  font-weight: var(--main-font-weight);
}

/* reset application.css */
.calendar__header th {
  font-weight: var(--medium-font-weight);
}
.calendar__header {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  font-weight: var(--medium-font-weight);

  text-transform: capitalize;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.calendar__arrow-icon {
  width: 5px;
  height: 8px;
}
.calendar__month {
  width: auto;
}
.calendar__arrow,
.calendar__month,
.calendar__weekday,
.calendar__day {
  text-align: center;
  vertical-align: middle;

  white-space: nowrap;

  width: 28px;
  min-width: 0;
  height: var(--calendar-day-size);

  padding: 2px;
}
.calendar__weekday {
  color: var(--gray-text-color);
}
.calendar__day,
.calendar__arrow_enabled {
  cursor: pointer;
}
.calendar__day {
  position: relative;
  padding: 2px 0 0;
}
.calendar__day_disabled,
.calendar__arrow_disabled {
  color: var(--gray-text-color);

  cursor: not-allowed;

  pointer-events: none;
}
.calendar__day-number {
  /* for vertical aligning text */
  line-height: var(--calendar-line-height);

  position: absolute;
  top: 0;
  right: 2px;

  width: var(--calendar-day-size);
  height: var(--calendar-day-size);

  border-radius: 50%;
}
.calendar__day_today .calendar__day-number {
  background-color: var(--gray-color);
  border: 1px solid var(--gray-color);
}
.calendar__day_start_date .calendar__day-number,
.calendar__day_end_date .calendar__day-number {
  color: var(--background-color);

  z-index: 2;

  background-color: var(--action-color);

  border: 1px solid var(--action-color);
}
.calendar__day-right-half,
.calendar__day-left-half {
  position: absolute;
  top: 0;
  z-index: 1;

  width: 14px;
  height: var(--calendar-day-size);

  padding-top: 2px;

  background: transparent;
}
.calendar__day-right-half {
  right: 0;
}
.calendar__day-left-half {
  left: 0;
}
.calendar__day_in_range,
.calendar__day_start_date .calendar__day-right-half,
.calendar__day_end_date .calendar__day-left-half {
  background-color: var(--action-background-color);
}
.calendar__day_in_range.calendar__day_off,
.calendar__day_start_date .calendar__day-left-half,
.calendar__day_end_date .calendar__day-right-half,
.calendar__day_start_date.calendar__day_end_date .calendar__day-right-half,
.calendar__day_start_date.calendar__day_end_date .calendar__day-left-half {
  background: transparent;
  border: transparent;
}
.calendar__day_in_range.calendar__day_start_date,
.calendar__day_in_range.calendar__day_end_date {
  background: transparent;
}
.calendar__day_in_range:last-child,
.calendar__day_in_range.calendar__day_last_of_month,
.calendar__day_in_range:last-child .calendar__day-right-half,
.calendar__day_in_range.calendar__day_last_of_month .calendar__day-right-half {
  border-radius: 0 5px 5px 0;
}
.calendar__day_in_range:first-child,
.calendar__day_in_range.calendar__day_first_of_month,
.calendar__day_in_range:first-child .calendar__day-left-half,
.calendar__day_in_range.calendar__day_first_of_month .calendar__day-left-half {
  border-radius: 5px 0 0 5px;
}
