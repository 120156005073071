
.pagination-goto {
  text-align: center;

  display: flex;

  align-items: center;

  margin-right: auto;
}
.pagination-goto__input {
  width: 50px
}
.pagination-goto__input .input__field {
    margin-bottom: 0;
}
.pagination-goto__label {
  margin-right: 8px;
}
@media (--small), (--extra-small) {
.pagination-goto {
    margin-right: 0;
    margin-right: initial;
}
.pagination-goto__label {
    margin-right: 3px;
}
}
@media (--extra-small) {
.pagination-goto {
    margin-top: 10px;
}
.pagination-goto__label {
    margin-right: 8px;
}
}
