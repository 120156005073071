
.m-notification {
  overflow: hidden;
  cursor: default;

  position: relative;
  display: inline-flex;

  align-items: stretch;

  width: 100%;

  background-color: var(--background-color);

  box-shadow: 0 2px 12px rgba(71, 71, 71, 0.1);

  border-radius: 5px;
}
.m-notification_success {
  --notification-color: var(--success-color);
}
.m-notification_warning {
  --notification-color: var(--warning-color);
}
.m-notification_error {
  --notification-color: var(--error-color);
}
.m-notification__close {
  color: var(--secondary-text-color);

  cursor: pointer;

  position: absolute;
  top: 6px;
  right: 6px;

  width: 14px;
  height: 14px;
}
.m-notification__close:hover {
  color: var(--text-color);
}
.m-notification__status-bar {
  flex-grow: 0;
  flex-shrink: 0;

  width: 4px;

  background: var(--notification-color);
}
.m-notification__contents {
  padding: 14px;
}
.m-notification__header {
  font-size: var(--subtitle-font-size);
  color: var(--notification-color);
}
.m-notification__text {
  font-size: var(--main-font-size);

  line-height: 18px;

  margin-top: 4px;
}
.m-notification__link {
  font-size: var(--main-font-size);
  margin-top: 8px;
}
.m-notification__link .m-link {
  line-height: var(--main-line-height);
}

