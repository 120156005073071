
.m-modal__header[data-v-0d9c7cfa] {
  font-size: 18px;
  font-weight: var(--medium-font-weight);

  color: var(--text-main-color);

  flex-shrink: 0;

  padding: 15px;
}
.m-modal__header_bordered[data-v-0d9c7cfa] {
  border-bottom: 1px solid var(--border-color);
}
.m-modal__content[data-v-0d9c7cfa] {
  overflow-y: auto;

  flex-grow: 1;
  flex-shrink: 1;

  min-height: 0;
}
.m-modal__footer[data-v-0d9c7cfa],
.m-modal__sub-footer[data-v-0d9c7cfa] {
  padding: 16px;
  border-top: 1px solid var(--border-color);
}
.m-modal__footer[data-v-0d9c7cfa] {
  flex-shrink: 0;
  padding: 16px 18px;
}
.m-modal__button-cancel[data-v-0d9c7cfa] {
  margin-left: 21px;
}
