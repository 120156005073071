
.m-hint .popper {
  box-shadow: 0 0 10px rgba(134, 134, 134, 0.25);
}
.m-hint__content-wrapper {
  font-size: 12px;

  /* center contents */
  display: flex;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 269px;
  min-width: 0;

  padding: 14px 12px 16px;
}
