
.m-table-cell-combined-control {
  display: flex;
  align-items: center;
}
.m-table-cell-combined-control__checkbox-container .m-checkbox__check-mark {
  margin-right: 0;
}
.m-table-cell-combined-control__dropdown-container {
  cursor: pointer;

  margin-left: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.m-table-cell-combined-control__notification-container {
  flex-grow: 0;
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  margin-left: 4px;
}
.m-table-cell-combined-control-hint {
  color: var(--warning-color);
}

