
.all-items-block-filtration__search {
  margin-top: 16px;
}
.all-items-block__choices {
  overflow-x: hidden;
  overflow-y: auto;

  width: 380px;

  margin-top: 12px;
}
.all-items-search__label {
  font-size: var(--main-font-size);
  font-weight: var(--medium-font-weight);

  color: var(--text-color);
  line-height: var(--main-line-height);

  white-space: nowrap;

  display: inline-block;

  padding: 0;

  margin-bottom: 15px;
}
.all-items-block-choices__item {
  padding: 6px 0;
}
.all-items-block__select-all {
  display: flex;
  align-items: center;
}
.all-items-block-select-all__help-icon {
  margin-left: 4px;
}
.all-items-block_filtration__group {
  display: flex;
  flex-shrink: 0;
}
.all-items-block_filtration__group-item {
  flex: 1 1;
  align-self: flex-end;
}
.all-items-block_filtration__group-item:not(:first-child) {
  margin-left: 12px;
}
.all-items-block .bound-and-disabled-checkbox-m-help {
  width: 365px;
  height: 16px;

  margin: 10px 0;
}
.all-items-block .bound-and-disabled-checkbox-m-help:nth-child(2) {
  margin: 5px 0 0;
}
.all-items-block .m-checkbox.all-items-block-choices__item ~ .bound-and-disabled-checkbox-m-help {
  margin-top: 5px;
}
.all-items-block .bound-and-disabled-checkbox-m-help .all-items-block-choices__item {
  width: 365px;

  padding: 0;

  margin: 0;
}

