
:root {
  --expanding-textarea-font-size: 14px;
  --expanding-textarea-height: 28px;
  --expanding-textarea-padding: 5px;
}
.m-expanding-input {
  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;
}
.m-expanding-input__field-wrapper {
  font-size: 14px;
  font-size: var(--expanding-textarea-font-size);

  display: flex;

  align-items: center;

  box-sizing: border-box;
  width: calc(var(--current-cell-width) - 20px);

  padding-left: 5px;

  padding-left: var(--expanding-textarea-padding);
  padding-right: 5px;
  padding-right: var(--expanding-textarea-padding);

  border: solid 1px var(--border-color);

  border-radius: var(--border-radius);

  transition: var(--input-transition);
}
.m-expanding-input__field-wrapper:hover {
  cursor: text;
  border-color: var(--action-color);
}
.m-expanding-input__field-wrapper span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.m-input_disabled {
  cursor: not-allowed;
}
.m-input_error {
  border-color: var(--error-color);
}
.m-expanding-input__grow-wrap {
  word-break: break-all;

  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;

  height: 28px;

  height: var(--expanding-textarea-height);
}
.m-expanding-input__grow-wrap::after {
  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.m-expanding-input__grow-wrap > textarea {
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;

  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
}
.m-expanding-input__grow-wrap > textarea,
.m-expanding-input__grow-wrap::after {
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;

  padding: 5px;

  padding: var(--expanding-textarea-padding);

  /* Identical styling required!! */
  border: 1px solid var(--border-color);

  border-radius: var(--border-radius);
}
.m-expanding-input__grow-wrap textarea {
  min-height: 28px;
  box-shadow: var(--box-shadow);
}
.m-expanding-input__grow-wrap textarea:focus {
  border-color: var(--action-color);
  outline: none;
}
.popper.m-expanding-input__popper {
  box-shadow: none;
}
