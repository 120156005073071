
.m-link {
  color: var(--action-color);

  vertical-align: middle;

  text-decoration: unset;

  cursor: pointer;

  display: inline-flex;

  align-items: center;

  /* deal with application.css */
  outline: unset;
  outline-offset: unset
}
.m-link.disabled {
    color: var(--action-disabled-color);
    cursor: not-allowed
}
.m-link.disabled:hover,
    .m-link.disabled:focus {
      color: var(--action-disabled-color);
}
.m-link:focus {
    color: var(--action-color);
}
.m-link:hover {
    color: var(--action-hover-color);
}
.m-link svg {
  vertical-align: middle;
  height: 1.1em;
}
.m-link__icon_before {
  margin-right: 4px;
}
.m-link__icon_after {
  margin-left: 4px;
}
