
.select-items {
  overflow-y: auto;

  position: relative;

  width: -webkit-max-content;

  width: -moz-max-content;

  width: max-content;
  max-height: 346px;

  border-top: 4px solid var(--background-color);
  border-bottom: 4px solid var(--background-color);

  border-radius: var(--border-radius);

  transition: opacity var(--transition-duration)
}
.select-items.select-items_small {
    font-size: 12px;
}
.select-items.select-items_large {
    font-size: 16px;
}
.popper-fade-leave-to,
.popper-fade-enter {
  transition: opacity var(--transition-duration);
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0.5;
  transform: scaleY(0);
}

