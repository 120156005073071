
.select-container {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.select-container__select {
  position: relative;

  flex-grow: 1;

  height: 32px;
}
.select-control {
  white-space: nowrap;

  cursor: pointer;

  display: flex;

  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  height: 32px;

  padding-left: 8px;
  padding-right: 8px;

  background: var(--background-color);

  border: 1px solid var(--border-color);

  border-radius: 5px;
}
.select-control__placeholder {
  font-size: var(--main-font-size);
  color: var(--gray-text-color);
}
.select-control__icon {
  color: var(--secondary-text-color);

  flex-shrink: 0;

  width: 18px;
  height: 18px;

  margin-left: 5px
}
.select-control__icon svg {
    transform: rotate(90deg);

    transition: transform ease-in-out var(--transition-duration);
}
.select-control__icon_active svg {
  transform: rotate(270deg);
}

/* hack to override bootstrap styles */
.select-container .select-control__input {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);

  padding: 0;

  margin: 0;

  border: none
}
.select-container .select-control__input:focus {
    border: none;
    outline: none;
}
.select-control__content {
  overflow: hidden;
  margin-left: 1px;
}
.select-container__label label {
    font-size: var(--main-font-size);
    font-weight: var(--medium-font-weight);

    color: var(--text-color);
    line-height: var(--main-line-height);

    white-space: nowrap;

    display: inline-block;

    padding: 0;

    margin: 0;
}
.select-container_small {
  font-size: 12px
}
.select-container_small .select-control__content {
    font-size: var(--secondary-font-size);
}
.select-container_small .select-control,
  .select-container_small .select-container__select {
    height: 28px;
}

/* stylelint-disable-next-line plugin/stylelint-group-selectors */
.select-container_large {
  font-size: 14px
}
.select-container_large .select-control__content {
    font-size: var(--main-font-size);
}
.select-container_large .select-control,
  .select-container_large .select-container__select {
    height: 40px;
}
.select-container_disabled {
  cursor: not-allowed
}
.select-container_disabled .select-container__label label {
    color: var(--gray-text-color);
    cursor: not-allowed;
}
.select-container_disabled .select-control {
    color: var(--gray-text-color);

    cursor: not-allowed;

    background: var(--gray-table-color);

    border: solid 1px var(--gray-table-color);
}
.select-container_large-with-icon:not(.select-container_large-placeholder-only-name) .select-control {
    padding-left: 14px;
    padding-right: 14px;
}
.select-container_large-with-icon:not(.select-container_large-placeholder-only-name) .select-control,
  .select-container_large-with-icon:not(.select-container_large-placeholder-only-name) .select-container__select {
    height: 62px;
}
.select-container_label-top .select-container__label label {
  margin-bottom: 8px;
}
.select-container_label-left {
  display: flex;

  flex-shrink: 0;
  align-items: center
}
.select-container_label-left .select-container__label label {
    margin-right: 8px;
}
.select-control__popper_in-modal {
  z-index: calc(var(--popover-z-index) + 1);
}
.select-control__no-padding.select-items {
  border: none;
}
.select-control__no-height-limit.select-items {
  max-height: unset;
}
