
.pagination-items-show {
  text-align: center;

  display: flex;

  flex-wrap: nowrap;
  align-items: center;

  margin-right: 18px;
}
.pagination-items-show__label {
  margin-right: 8px;
}
@media (--extra-small), (--small) {
.pagination-items-show {
    margin-right: 7px;
}
}
@media (--extra-small) {
.pagination-items-show {
    margin-top: 10px;
    margin-right: 0;
    margin-right: initial;
}
}
