
.columns-order-item {
  font-size: 12px;

  color: var(--text-main-color);

  position: relative;

  padding: 5px 8px 5px 12px;

  margin: 10px 0;

  background-color: var(--background-color);
  box-shadow: 0 2px 8px rgba(176, 176, 176, 0.25);

  border-radius: 5px;
}
.columns-order-item_grouped {
  padding: 8px 8px 8px 12px;
}
.columns-order-item__group-info {
  display: flex;

  align-items: center;

  margin-bottom: 12px;
}
.columns-order-item-value {
  display: flex;
}
.columns-order-item .columns-order-item-value ~ .columns-order-item-value {
  padding-top: 8px;

  margin-top: 8px;

  border-top: solid 1px var(--border-color);
}
.order-item-group-info__name {
  font-weight: var(--medium-font-weight);
  color: var(--secondary-text-color);
}
.order-item-group-info__icon {
  width: 12px;
  height: 12px;

  margin-right: 4px;
}
.columns-order-item-value__remove {
  color: var(--secondary-text-color);

  cursor: pointer;

  flex-grow: 0;
  flex-shrink: 0;

  width: 14px;
  height: 14px;

  margin-left: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
