
.extended-select-dropdown-controls {
  display: flex;

  flex-direction: column;

  height: 100%;
}
.extended-select-dropdown-controls__buttons {
  display: flex;

  justify-content: flex-end;
  align-items: flex-end;

  height: 100%;
}
.extended-select-dropdown-controls__cancel {
  margin-right: 12px;
}
.extended-select-dropdown-error {
  display: flex;
}
.extended-select-dropdown-error__icon {
  color: var(--error-color);

  width: 20px;
  height: 20px;
}
.selected-items-error__error-text {
  font-size: 12px;

  color: var(--secondary-text-color);

  width: 87%;

  margin-left: 8px;
}
