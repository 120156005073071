
.small-spinner {
  -webkit-animation: rotation 1000ms cubic-bezier(0.26, 0.05, 0.24, 0.85) infinite;
          animation: rotation 1000ms cubic-bezier(0.26, 0.05, 0.24, 0.85) infinite;
  stroke: var(--panel-text-color);
}
.small-spinner-circle {
  stroke-linecap: round;
}
@-webkit-keyframes rotation {
100% {
    transform: rotate(360deg);
}
}
@keyframes rotation {
100% {
    transform: rotate(360deg);
}
}
