
.m-lazy-render__lazy-dummy-node {
  height: var(--dummy-node-height);
}
.m-lazy-render__lazy-enabled .select-option__text {
  white-space: nowrap;

  display: block;

  min-width: 0;
}
