
.date-range-picker__dropdown-calendar-wrapper {
  color: inherit;

  z-index: 3001;

  box-sizing: border-box;
  width: 476px;

  padding: 22px 24px 15px;

  margin-top: 2px;

  background-color: var(--background-color);

  border-radius: var(--border-radius);
}
.date-range-picker__calendars {
  display: flex;

  flex-direction: row;
  justify-content: space-between;
}
.date-range-picker__calendar_name {
  font-size: var(--secondary-font-size);

  color: var(--panel-text-color);

  text-align: center;
}
.date-range-picker__ranges {
  margin: 6px 0 0;
}
.date-range-picker__ranges .tag-list .tag-list__tag {
  margin-top: 8px;
}
.date-range-picker__buttons {
  line-height: 12px;

  text-align: right;
  vertical-align: middle;

  display: none;
  clear: both;

  padding: 20px 0 0;

  border: none;
}
.date-range-picker__button {
  cursor: pointer;
  margin-left: 18px;
}
.date-range-picker__button-reset,
.date-range-picker__button-success {
  font-family: var(--main-font-family);
  font-size: var(--subtitle-font-size);
  font-weight: var(--medium-font-weight);

  padding-bottom: 0;

  background: transparent;

  border: none;

  outline: none;
}
.date-range-picker__button-reset {
  color: var(--gray-text-color);
}
.date-range-picker__button-success {
  color: var(--action-color);
}
.date-range-picker__button-success:hover {
  color: var(--text-link-blue-hover-color);
}
.date-range-picker__dropdown-calendar-wrapper,
.date-range-picker__dropdown-calendar-wrapper .date-range-picker__calendar,
.date-range-picker__dropdown-calendar-wrapper .date-range-picker__buttons {
  display: block;
}
