
.tree-select-dropdown-tree__search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;

  padding: 14px 16px 4px;

  background: var(--background-color);
}
.tree-select-dropdown-tree {
  overflow-y: auto;

  width: 282px;
  height: 100%;
}
.tree-select-dropdown-tree__node-skeleton {
  padding: 16px 20px 0;
}
