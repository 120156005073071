
.pagination {
  font-size: var(--secondary-font-size);

  display: flex;

  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  margin-top: 12px;
  margin-bottom: 30px;
}
@media (--extra-small) {
.pagination {
    flex-direction: column;
}
}
