
.pagination-pages {
  --color-active-page: #15385C;

  font-size: 14px;
  font-weight: var(--medium-font-weight);

  color: var(--action-color);

  position: absolute;
  display: flex;

  justify-content: left;
  align-items: center;
}
.pagination-pages__item {
  cursor: pointer;

  padding-left: 5px;
  padding-right: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.pagination-pages__page-number.pagination-pages__page-number_active {
    color: var(--color-active-page);
}
.pagination-pages__page-number.pagination-pages__page-number_active:hover {
    color: var(--color-active-page);
    cursor: default;
}
.pagination-pages__arrow-icon {
  width: 5px;
  height: 28px
}
.pagination-pages__arrow-icon:hover {
    cursor: pointer;
}
.pagination-pages__arrow-icon.pagination-pages__arrow-icon_disabled {
    color: var(--action-disabled-color);
}
.pagination-pages__arrow-icon.pagination-pages__arrow-icon_disabled:hover {
    cursor: not-allowed;
}
@media (--extra-small), (--small) {
.pagination-pages {
    position: inherit;
    margin-right: auto;
}
}
@media (--extra-small) {
.pagination-pages {
    order: -1;
    margin-right: 0;
    margin-right: initial;
}
}
