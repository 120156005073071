
.m-table-cell {
  text-overflow: ellipsis;

  white-space: nowrap;
  word-break: break-all;
  word-wrap: break-word;

  overflow: hidden;

  display: flex;

  align-items: center;

  box-sizing: border-box;
  width: var(--current-cell-width);
  min-width: 0;
  height: 36px;

  padding: 4px 8px;
}
.m-table-cell__ellipsis-wrapper {
  text-overflow: ellipsis;

  overflow: hidden;

  display: inline-block;

  width: 100%;
  min-width: 0;
}
