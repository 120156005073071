
.m-modal[data-v-7e20dd48] {
  --background-tint-color: var(--modal-background-tint-color);
  --modal-background-color: var(--background-color);

  transition: opacity 200ms;
}
.m-modal__wrapper-hidden[data-v-7e20dd48] {
  opacity: 0;
}
.m-modal__screen-tint[data-v-7e20dd48] {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--popover-z-index);

  width: 100%;
  height: 100%;

  background-color: var(--background-tint-color);
}
.m-modal__position-wrapper[data-v-7e20dd48] {
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
.m-modal__position-wrapper.m-modal__position-wrapper_fullscreened[data-v-7e20dd48] {
  overflow-y: auto;

  align-items: flex-start;

  padding-top: 8px;
  padding-bottom: 8px;
}
.m-modal__container[data-v-7e20dd48] {
  overflow: hidden;

  display: flex;

  flex-direction: column;

  box-sizing: border-box;

  background-color: var(--modal-background-color);
  box-shadow: 0 4px 15px rgba(168, 168, 168, 0.25);

  border-radius: 5px;
}
