
.table-settings-save-buttons__cancel {
  margin-left: 12px;
}
.table-settings-save-options {
  margin-bottom: 16px;
}
.table-settings-save-options > .table-settings-save-options__option ~ .table-settings-save-options__option {
  margin-top: 16px;
}
