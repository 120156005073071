
.m-table-header_indicator_row {
  /* sticky table columns have 20 */
  z-index: 21;
}
#m-table-header-lazy-resize-indicator {
  position: absolute;
  display: none;
  top: 0;

  background: var(--action-secondary-color);
}
