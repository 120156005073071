
.tree-select-dropdown-node-container {
  padding-left: var(--tree-node-indent);
  padding-right: 2px;
}
.tree-select-dropdown-node {
  font-size: 14px;

  overflow: hidden;

  display: flex;

  align-items: center;

  width: 100%;
  height: 24px
}
.tree-select-dropdown-node:focus {
    background-color: var(--action-background-color);
}
.tree-select-dropdown-node__no-child {
  font-size: 12px;

  color: var(--gray-text-color);

  display: flex;

  align-items: center;

  height: 18px;

  margin-left: 46px;
}
.tree-select-dropdown-node__no-child_no-select {
  margin-left: 24px;
}
.tree-select-dropdown-node__open-trigger {
  cursor: pointer;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  margin-left: 4px;
  margin-right: 4px;

  border-radius: 2px;
}
.tree-select-dropdown-node__open-trigger:hover {
  background: var(--action-background-color);
}
.tree-select-dropdown-node__open-trigger_no-child {
  cursor: default;
  opacity: 0;
}
.tree-select-dropdown-node__open-trigger_no-child:hover {
  background: none;
}
.tree-select-dropdown-node__open-trigger svg {
  width: 16px;
  height: 16px;

  transition: 150ms;
}
.tree-select-dropdown-node__open-trigger_active svg {
  transform: rotate(90deg);
}
.tree-select-dropdown-node__checkbox,
.tree-select-dropdown-node__text {
  overflow: hidden;
  width: 100%;
}
.tree-select-dropdown-node__text {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-select-dropdown-node_loading {
  margin: 8px 24px;
}
