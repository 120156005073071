
.m-multiselect {
  --options-text-color: var(--secondary-text-color);
  --active-text-color: var(--secondary-text-color);
}
.m-multiselect__content {
  font-size: var(--main-font-size);

  color: var(--active-text-color);
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;
}
.m-multiselect__content_small {
  font-size: 12px;
}
.m-multiselect__content_empty {
  color: var(--gray-text-color);
}
.m-multiselect__selected-divider {
  padding: 0 5px;

  margin: 5px 0;

  border-bottom: solid 1px var(--border-color);
}
.m-multiselect__group-icon {
  flex-shrink: 0;

  width: 12px;
  height: 12px;

  padding-top: 8px;
  padding-bottom: 8px;

  margin-right: 4px;
}
.m-multiselect__group {
  font-weight: bolder; /* stylelint-disable-line sh-waqar/declaration-use-variable */

  position: -webkit-sticky;

  position: sticky;
  display: flex;
  top: 0;

  flex-shrink: 0;
  align-items: flex-start;

  min-height: 28px;

  padding-left: 8px;
  padding-right: 24px;

  background-color: var(--background-color);
}
.m-multiselect__group_sticky {
  box-shadow: 0 1px 4px rgba(129, 129, 129, 0.25);
}
.m-multiselect__group_sticky ~ .m-multiselect__group_sticky {
  box-shadow: none;
}
.m-multiselect__group-text {
  line-height: 16px;
  text-overflow: ellipsis;

  white-space: unset;

  overflow: hidden;

  /* webkit-box not working in firefox, but in this case it is ok, it will not break layout */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */

  padding-top: 6px;
  padding-bottom: 6px;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable property-no-vendor-prefix */
}
.m-multiselect__choice {
  flex-grow: 1;
  align-items: baseline;

  min-height: 28px;

  padding-left: 8px
}
.m-multiselect__choice.select-option_focus,
  .m-multiselect__choice:hover {
    background-color: var(--action-background-color);
}
.m-multiselect__search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  box-sizing: border-box;
  height: 28px;

  padding-top: 5px;
  padding-left: 8px;
  padding-right: 8px;

  margin-top: 9px;
  margin-bottom: 8px;
}
.m-multiselect__choice .m-checkbox__label {
  font-size: 14px;

  color: var(--options-text-color);
  line-height: 16px;

  white-space: unset;

  /* webkit-box not working in firefox, but in this case it is ok, it will not break layout */
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */

  flex-grow: 1;

  margin: 6px 24px 6px 8px;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable property-no-vendor-prefix */
}
.m-multiselect__choice.m-checkbox .m-checkbox__check-mark {
  margin: 0;
}
.m-multiselect__no-items-message {
  font-size: 12px;

  color: var(--gray-text-color);

  margin: 8px 8px 4px;
}
.m-multiselect__actions-container {
  text-align: right;

  position: -webkit-sticky;

  position: sticky;
  bottom: 0;

  padding: 8px 18px 4px 8px;

  background-color: var(--background-color);
}
.m-multiselect__actions-reset_button {
  font-size: 16px;
  font-weight: 500;
}
.m-multiselect__actions-apply_button {
  font-size: 16px;
  font-weight: 500;

  margin-left: 18px;
}
.m-multiselect__skeleton-dropdown {
  overflow-y: auto;
  margin-bottom: 16px;
}
.m-multiselect__skeleton-dropdown-node {
  padding: 16px 20px 0;
}
