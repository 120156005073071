
.extended-select-dropdown {
  display: grid;

  grid-template-columns: 400px 300px;
  grid-template-rows: 62px auto 64px;

  box-sizing: border-box;
  height: 418px;
}
.extended-select-dropdown__title {
  font-size: 18px;
  font-weight: var(--medium-font-weight);

  grid-row: 1/2;

  padding: 16px 20px;

  border-bottom: 1px solid var(--border-color);
}
.extended-select-dropdown__title .extended-select-dropdown__subtitle {
  font-size: 12px;
  font-weight: 400;

  color: var(--panel-text-color);

  display: block;

  padding-top: 2px;
}
.extended-select-dropdown__available-items {
  overflow-y: auto;

  grid-row: 2/4;

  box-sizing: border-box;

  padding: 16px 20px;
}
.extended-select-dropdown__selected-items {
  overflow-y: auto;

  grid-row: 1/3;

  box-sizing: border-box;

  padding: 18px 12px 6px;
}
.extended-select-dropdown__controls {
  box-sizing: border-box;

  padding: 12px 16px 16px 12px;

  border-top: 1px solid var(--border-color);
}
.extended-select-dropdown__selected-items,
.extended-select-dropdown__controls {
  border-left: 1px solid var(--border-color);
}
