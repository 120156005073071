
.skeleton-wrapper_rounded {
  border-radius: 10px;
}
.skeleton-wrapper__loader {
  overflow: hidden;

  position: relative;

  background-color: var(--skeleton-loader-background-color);
}
.skeleton-wrapper__loader::after {
  content: '';

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0,
      rgba(200, 200, 200, 0.2) 30%,
      rgba(200, 200, 200, 0.3) 50%,
      rgba(0, 0, 0, 0)
    );

  transform: translateX(-100%);

  -webkit-animation: skeleton-loader 2500ms infinite;

          animation: skeleton-loader 2500ms infinite;
@-webkit-keyframes skeleton-loader {
100% {
      transform: translateX(100%);
}
}
@keyframes skeleton-loader {
100% {
      transform: translateX(100%);
}
}
}

