
.table-settings-preset-item {
  font-size: 12px;

  display: flex;

  align-items: center;

  height: 28px;

  padding-left: 12px;
  padding-right: 14px;
}
.table-settings-preset-item:hover {
  background-color: var(--action-background-color);
}
.table-settings-preset-item .m-radiobutton {
  overflow: hidden;
  min-width: 0;
}
.table-settings-preset-item .m-radiobutton .m-radiobutton__label {
  color: var(--secondary-text-color);
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;

  min-width: 0;

  margin-right: 0;
}
.table-settings-preset-item .m-radiobutton .m-radiobutton__check {
  flex-grow: 0;
  flex-shrink: 0;
}
.table-settings-preset-item__controls {
  display: none;
  margin-left: auto;
}
.table-settings-preset-item:hover .table-settings-preset-item__controls {
  display: flex;
}
.table-preset-controls__icon {
  color: var(--gray-text-color);

  cursor: pointer;

  flex-shrink: 0;

  width: 14px;
  height: 14px;

  margin-left: 4px;
}
.table-preset-controls__edit:hover {
  color: var(--action-hover-color);
}
.table-preset-controls__delete:hover {
  color: var(--error-hover-color);
}

