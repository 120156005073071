
.m-table-header-sorting {
  display: flex;

  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  margin-left: 4px;
}
.m-table-header-sorting__icon {
  color: var(--gray-text-color);

  flex-grow: 0;
  flex-shrink: 0;

  width: 5px;
}
.m-table-header-sorting__asc-icon {
  transform: rotate(-90deg) translateX(-1px);
}
.m-table-header-sorting__desc-icon {
  transform: rotate(-90deg) scaleX(-1) translateX(-1px);
}
.m-table-header-sorting_asc svg,
.m-table-header-sorting_desc svg {
  color: var(--main-color);
}
.m-table-header-sorting_disabled {
  cursor: not-allowed;
}
.m-table-header-sorting_disabled svg {
  color: var(--border-color);
}
.m-table-header-sorting_desc .m-table-header-sorting__asc-icon,
.m-table-header-sorting_asc .m-table-header-sorting__desc-icon {
  display: none;
}
.m-table-header-sorting_desc .m-table-header-sorting__desc-icon {
  position: relative;
  top: 2px;
}
.m-table-header-sorting_asc .m-table-header-sorting__asc-icon {
  position: relative;
  bottom: 2px;
}

