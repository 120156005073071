
.m-provider-icon {
  position: relative
}
.m-provider-icon._is-svg .m-provider-icon-image {
      overflow: visible;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
}
.hidden-svg {
  position: absolute;

  width: auto;
  height: 100%;

  visibility: hidden;
}
.svg-container {
  overflow: hidden;
  height: 0;
}
