
.tree-select-dropdown.select-items {
  height: 387px;
  max-height: unset;

  border: none;
}
.tree-select__placeholder {
  color: var(--gray-text-color);
}
