
.m-select-control-create-new-option {
  color: var(--gray-text-color);

  cursor: pointer;

  display: flex;

  padding: 8px;
}
.m-select-control-create-new-option:hover {
  background: var(--action-background-color);
}
.m-select-control-create-new-option__text {
  margin-left: 4px;
}
.m-select-control-create-new-option__icon {
  width: 16px;
  height: 16px;
}
