
.m-select-control-tree-level {
  --sticky-top-offset: 0px;
  --sticky-z-index: 0;
}
.m-select-control-tree-level__sticky {
  position: -webkit-sticky;
  position: sticky;
  top: var(--sticky-top-offset);
  z-index: var(--sticky-z-index);
}
