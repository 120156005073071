
.spinner-container {
  --spinner-half-size: 23px;
  --spinner-offset-y: 0;

  position: relative;

  width: 100%;
  height: 100%;
}
.spinner-container__spinner {
  position: absolute;
  top: calc(50% - var(--spinner-half-size) - var(--spinner-offset-y));
  left: calc(50% - var(--spinner-half-size));

  stroke: var(--main-color);
}
.spinner-container__overlay {
  position: absolute;
  z-index: var(--popover-shading-z-index);

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.5);
}
.spinner-container_full-height {
  min-height: 80vh;
}

