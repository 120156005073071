
.header-tab-item {
  font-size: 14px;

  color: var(--secondary-text-color);

  cursor: pointer;

  box-sizing: border-box;

  padding: 6px 12px;

  border: solid 1px var(--border-color);

  border-radius: 4px;
}
.header-tab-item:hover {
  background: var(--action-border-color);
  border-color: transparent;
}
.header-tab-item_active {
  background: var(--action-secondary-color);
  border-color: transparent;
}
