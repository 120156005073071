
.m-button {
  --secondary-button-hover-color: var(--secondary-button-color-hover);

  font-family: inherit; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);

  /* reset bootstrap styles */
  line-height: unset;

  cursor: pointer;

  /* for spinner absolute positioning */
  position: relative;
  display: inline-flex;

  align-items: center;

  box-sizing: border-box;
  height: 32px;

  padding: 0 14px;

  background-color: unset; /* stylelint-disable-line sh-waqar/declaration-use-variable */

  border: unset;

  border-radius: 5px;

  outline: none;

  transition: var(--transition-duration) ease-in-out
}
.m-button.m-button_disabled {
    cursor: not-allowed;
}
.m-button {

  /* stylelint plugin stylelint-group-selectors does not understand mixins */

  /* stylelint-disable plugin/stylelint-group-selectors */
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading) {
    color: var(--background-color);
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link) {
    background-color: var(--action-color)
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link):hover {
      background-color: var(--action-hover-color);
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link):active {
      box-shadow: inset 0 2px 4px var(--action-active-color)
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link):active .m-button__text,
      .m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link):active .m-button__icon {
        padding-top: 2px; /* cause of height of the text is less then block height on 1px */
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link).m-button_light {
      color: var(--action-color);

      background-color: var(--background-color);

      border: 1px solid var(--action-color)
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading):not(.m-button_disabled):not(.m-button_link).m-button_light:hover {
        color: var(--background-color);
        background-color: var(--action-hover-color);
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading).m-button_disabled {
    color: var(--background-color);
    background-color: var(--action-disabled-color)
}
.m-button:not(.m-button_critical):not(.m-button_secondary):not(.m-button_link):not(.m-button_gray):not(.m-button_loading).m-button_disabled.m-button_light {
      color: var(--action-disabled-color);

      background-color: var(--background-color);

      border: 1px solid var(--action-disabled-color);
}
.m-button.m-button_critical {
    color: var(--background-color);
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link) {
    background-color: var(--error-color)
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link):hover {
      background-color: var(--error-hover-color);
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link):active {
      box-shadow: inset 0 2px 4px var(--error-active-color)
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link):active .m-button__text,
      .m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link):active .m-button__icon {
        padding-top: 2px; /* cause of height of the text is less then block height on 1px */
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link).m-button_light {
      color: var(--error-color);

      background-color: var(--background-color);

      border: 1px solid var(--error-color)
}
.m-button.m-button_critical:not(.m-button_disabled):not(.m-button_link).m-button_light:hover {
        color: var(--background-color);
        background-color: var(--error-hover-color);
}
.m-button.m-button_critical.m-button_disabled {
    color: var(--background-color);
    background-color: var(--error-disabled-color)
}
.m-button.m-button_critical.m-button_disabled.m-button_light {
      color: var(--error-disabled-color);

      background-color: var(--background-color);

      border: 1px solid var(--error-disabled-color);
}
.m-button.m-button_gray {
    color: var(--background-color);
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link) {
    background-color: var(--gray-text-color)
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link):hover {
      background-color: var(--panel-text-color);
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link):active {
      box-shadow: inset 0 2px 4px var(--secondary-text-color)
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link):active .m-button__text,
      .m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link):active .m-button__icon {
        padding-top: 2px; /* cause of height of the text is less then block height on 1px */
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link).m-button_light {
      color: var(--gray-text-color);

      background-color: var(--background-color);

      border: 1px solid var(--gray-text-color)
}
.m-button.m-button_gray:not(.m-button_disabled):not(.m-button_link).m-button_light:hover {
        color: var(--background-color);
        background-color: var(--panel-text-color);
}
.m-button.m-button_gray.m-button_disabled {
    color: var(--background-color);
    background-color: var(--border-color)
}
.m-button.m-button_gray.m-button_disabled.m-button_light {
      color: var(--border-color);

      background-color: var(--background-color);

      border: 1px solid var(--border-color);
}
.m-button.m-button_secondary {
    color: var(--secondary-text-color);
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link) {
    background-color: var(--secondary-button-color)
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link):hover {
      background-color: var(--secondary-button-hover-color);
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link):active {
      box-shadow: inset 0 2px 4px var(--secondary-button-active-color)
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link):active .m-button__text,
      .m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link):active .m-button__icon {
        padding-top: 2px; /* cause of height of the text is less then block height on 1px */
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link).m-button_light {
      color: var(--secondary-button-color);

      background-color: var(--background-color);

      border: 1px solid var(--secondary-button-color)
}
.m-button.m-button_secondary:not(.m-button_disabled):not(.m-button_link).m-button_light:hover {
        color: var(--secondary-text-color);
        background-color: var(--secondary-button-hover-color);
}
.m-button.m-button_secondary.m-button_disabled {
    color: var(--secondary-button-disabled-text-color);
    background-color: var(--secondary-button-disabled-color)
}
.m-button.m-button_secondary.m-button_disabled.m-button_light {
      color: var(--secondary-button-disabled-color);

      background-color: var(--background-color);

      border: 1px solid var(--secondary-button-disabled-color);
}
.m-button {
  /* stylelint-enable */
}
.m-button.m-button_link {
    font-weight: var(--medium-font-weight);

    padding: 0;

    border: none
}
.m-button.m-button_link:not(.m-button_critical):not(.m-button_secondary):not(.m-button_disabled):not(.m-button_gray) {
      color: var(--action-color)
}
.m-button.m-button_link:not(.m-button_critical):not(.m-button_secondary):not(.m-button_disabled):not(.m-button_gray):hover {
        color: var(--action-hover-color);
}
.m-button.m-button_link.m-button_critical {
      color: var(--error-color)
}
.m-button.m-button_link.m-button_critical:hover {
        color: var(--error-hover-color);
}
.m-button.m-button_link.m-button_gray {
      color: var(--gray-text-color)
}
.m-button.m-button_link.m-button_gray:hover {
        color: var(--panel-text-color);
}
.m-button.m-button_link.m-button_secondary {
      color: var(--secondary-button-color)
}
.m-button.m-button_link.m-button_secondary:hover {
        color: var(--secondary-button-hover-color);
}
.m-button.m-button_link.m-button_disabled {
      color: var(--action-disabled-color);
}
.m-button.m-button_small {
    font-size: var(--secondary-font-size);
    height: 28px;
}
.m-button.m-button_large {
    font-size: 16px;

    height: 42px;

    padding: 0 32px;
}
.m-button .m-button__icon {
    width: var(--main-line-height);
    height: var(--main-line-height);

    margin-right: 4px;
}
.m-button .m-button__icon_right {
    margin-left: 4px;
    margin-right: 0;
}
.m-button.m-button_loading {
    cursor: not-allowed;

    background-color: var(--gray-table-color);

    /* setting border via box-shadow, because `border` property adds pixels to button size */
    box-shadow: inset 0 0 0 1px var(--border-color)

    /* set opacity to zero for icon and text to preserve button content width */
}
.m-button.m-button_loading .m-button__icon,
    .m-button.m-button_loading .m-button__text {
      opacity: 0;
}
.m-button__spinner {
  /* center spinner inside button */
  position: absolute;
  top: 50%;
  left: 50%;

  /* spinner dimensions are 14px */
  margin-top: -7px;
  margin-left: -7px;
}
