
.tree-select-dropdown-controls {
  display: flex;

  align-items: center;

  padding: 0 22px;
}
.tree-select-dropdown-controls .m-button {
  font-size: 16px;
}
.tree-select-dropdown-controls__reset {
  margin-left: auto;
}
.tree-select-dropdown-controls__apply {
  margin-left: 18px;
}
.tree-select-dropdown-controls__apply--disabled,
.tree-select-dropdown-controls__apply--disabled:hover {
  color: var(--action-disabled-color);
  cursor: default;
}

