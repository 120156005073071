
.selected-item {
  display: flex;

  background: var(--gray-table-color);

  border-radius: 4px;
}
.selected-item.selected-item__error {
  background: rgba(234, 173, 176, 0.5);
}
.selected-item__text {
  font-size: 12px;

  color: var(--secondary-text-color);
  text-overflow: ellipsis;

  white-space: nowrap;

  overflow: hidden;

  min-width: 0;

  padding: 4px 8px;
}
.selected-item__delete {
  color: var(--secondary-text-color);

  cursor: pointer;

  display: flex;

  width: 12px;
  height: 12px;

  margin: 5px 4px 5px auto;
}

