
.table-settings-presets {
  cursor: default;

  max-width: 246px;
  min-width: 140px;

  padding-bottom: 5px;
}
.table-settings-presets__group-name {
  font-size: 12px;
  font-weight: var(--medium-font-weight);

  color: var(--text-main-color);

  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 4px;

  margin-right: 12px;
}
.table-settings-presets__not-found {
  font-size: 12px;

  color: var(--secondary-text-color);

  white-space: nowrap;

  padding: 12px 12px 7px;
}

