
.table-tabs-container {
  display: flex;
}
.table-tabs-container__controls {
  flex-shrink: 0;
  margin: 0 auto;
}
.table-tabs {
  display: flex;

  justify-content: center;
  align-items: center;
}
.table-tabs__label {
  font-size: 14px;
  font-weight: var(--medium-font-weight);

  color: var(--gray-text-color);

  margin-right: 6px;
}

/* stylelint-disable plugin/stylelint-group-selectors */
.table-tabs__items {
  display: flex;
}
.table-tabs__item {
  margin: 0 6px;
}

