
.m-table-row {
  --row-color: var(--background-color);
  --row-hover-color: var(--action-background-color);
  --custom-text-color: #C3C3C3;
  --custom-row-color: #C4C4C4;
  --disabled-row-color: #F3F3F3;
  --custom-link-color: inherit;
  --row-cell-background: var(--row-color);
}
.m-table-row_custom {
  --row-cell-background: var(--custom-row-color);

  color: var(--custom-text-color);
  background-color: var(--custom-row-color);
}
.m-table-row_custom .m-table-cell {
  color: var(--custom-text-color);
}
.m-table-row_custom .m-table-cell a {
  color: var(--custom-link-color);
}
.m-table-row_colored {
  --row-cell-background: var(--row-color);

  background: var(--row-color);
}
.m-table-row_opened-dropdown {
  --row-cell-background: var(--row-hover-color);

  background: var(--row-hover-color);
}
.m-table-row_colored.m-table-row_disabled {
  --row-cell-background: var(--gray-table-color);

  background-color: var(--gray-table-color);
}

/* stylelint-disable no-descending-specificity */
.m-table-row_selected,
.m-table-row_colored:hover,
.m-table-row_selected.m-table-row_disabled,
.m-table-row_selected.m-table-row_colored.m-table-row_disabled:hover {
  --row-cell-background: var(--row-hover-color);

  background-color: var(--row-hover-color);
}
.m-table-row_colored.m-table-row_disabled:hover {
  --row-cell-background: var(--gray-color);

  background-color: var(--gray-color);
}
.m-table-row_nested-expanded {
  --row-cell-background: var(--action-background-color);

  background-color: var(--action-background-color);
}
.m-table-row__summary,
.m-table-row__summary:hover {
  --row-cell-background: var(--background-color);

  background-color: var(--background-color);
}
