
.tree-select-dropdown {
  display: flex;

  flex-direction: column;

  height: 100%;
}
.tree-select-list {
  display: flex;
  height: 340px;
}
.tree-select-list > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.tree-select-list__tree {
  border-right: 1px solid var(--border-color);
}
.tree-select-dropdown__controls {
  flex-grow: 0;
  flex-shrink: 0;

  height: 46px;

  border-top: 1px solid var(--border-color);
}
