
.select-control__placeholder {
  font-size: var(--main-font-size);

  color: var(--gray-text-color);
  text-overflow: ellipsis;

  overflow: hidden;
}
.select-container_small .select-control__placeholder {
  font-size: var(--secondary-font-size);
}
.select-options_loading .select-options__skeleton-item {
  padding-top: 6px;
  padding-bottom: 6px;
}
.select-options_loading .select-options__skeleton-item:first-child {
  padding-top: 12px;
}
.select-control__grouped_options {
  padding: 0
}
.select-control__grouped_options > * {
    text-overflow: ellipsis;

    overflow: hidden;

    padding-left: 14px;
    padding-right: 24px;
}
.m-error-wrapper__active .select-control {
  border-color: var(--error-color);
}
.select-control__options-not-found {
  font-size: 12px;

  color: var(--gray-text-color);

  margin: 4px 8px;
}
.m-select-control__search {
  padding: 12px 8px 8px;
}
.m-select-control__actions-container {
  text-align: right;

  position: -webkit-sticky;

  position: sticky;
  bottom: 0;

  padding: 8px 18px 4px 8px;

  background-color: var(--background-color);
}
.m-select-control__actions-reset_button {
  font-size: 16px;
  font-weight: 500;
}
.m-select-control__choices-list_static.m-select-control-tree-level {
  width: var(--static-option-width);
}
