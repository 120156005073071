
.m-calendar-input {
  position: relative;
}
.m-calendar-input .m-input__icon {
  color: var(--main-color);

  position: absolute;
  top: 7px;
  right: 7px;

  width: 16px;
  height: 16px;
}
.m-calendar-input.m-input_small .m-input__icon {
  top: 5px;
}
.m-calendar-input.m-input_large .m-input__icon {
  top: 10px;
  right: 10px;
}
