
.m-table-cell-dropdown-trigger__button {
  cursor: pointer;

  display: flex;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  padding: 2px;

  margin-top: -1px;

  border-radius: 2px;
}
.m-table-cell-dropdown-trigger {
  cursor: pointer;

  padding-left: 0;
  padding-right: 0;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}
.m-table-cell-dropdown-trigger__state-icon {
  color: var(--text-main-color);

  width: 14px;

  transform: rotate(270deg);

  transition: var(--transition-duration);
}
.m-table-cell-dropdown-trigger__state-icon_opened {
  transform: rotate(360deg);
}
.m-table-cell-dropdown-trigger_disabled {
  color: var(--gray-text-color);
  cursor: not-allowed;
}
.m-table-cell-dropdown-trigger__button:hover {
  background: var(--action-border-color);
}
.m-table__row_disabled .m-table-cell-dropdown-trigger__button:hover {
  background: var(--border-color);
}
.m-table-cell-dropdown-trigger_disabled .m-table-cell-dropdown-trigger__button:hover {
  background: inherit;
}
