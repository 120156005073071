
.m-table {
  --m-table-font-size-s: 12px;
  --m-table-font-size-m: 14px;

  overflow: auto;

  max-height: 650px;

  padding-bottom: 6px;

  box-shadow: var(--floating-card-shadow);

  border-radius: 4px;

  /* hack to prevent scroll from lagging */
  transform: translateZ(0)
}
.m-table.m-table-font-size-s {
    font-size: var(--m-table-font-size-s);
}
.m-table.m-table-font-size-m {
    font-size: var(--m-table-font-size-m);
}
.m-table__body {
  color: var(--text-main-color);

  border-spacing: 0;
  border-collapse: separate;
  border-collapse: initial;
}
.m-table__body th,
.m-table__body td {
  padding: 0;
}
.m-table_with-summary .m-table-row__first * {
  font-style: italic;
  font-weight: var(--bold-font-weight);
}
.m-table__sticky-trigger {
  width: var(--table-width);
}
