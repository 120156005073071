
.table-settings-modal-container .m-modal__header {
  display: flex;
  align-items: center;
}
.table-settings-modal {
  display: flex;
  height: 450px;
}
.table-settings-modal__items,
.table-settings-modal__order {
  overflow-y: auto;

  flex-grow: 0;
  flex-shrink: 0;

  min-height: 100%;
}
.table-settings-modal__items {
  width: 402px;
}
.table-settings-modal__order {
  width: 266px;
  background: var(--gray-table-color);
}
.table-settings-modal__reset {
  font-size: 14px;
  font-weight: var(--medium-font-weight);

  margin-left: auto;
}
