
.m-inline-search {
  /* constants. Can be change outside */
  --border-idle-color: var(--border-color);
  --border-focused-color: var(--action-color);

  /* variables. Shouldn't be changed outside */
  --current-border-color: var(--border-idle-color);
  --border-transition: border linear 200ms, box-shadow linear 200ms;
  --search-icon-color: #4F4F4F;

  display: flex;

  flex-wrap: nowrap;
  align-items: flex-end;

  background-color: var(--background-color);
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.m-inline-search input[type='text'].m-inline-search__input {
  font-size: var(--main-font-size);

  flex-grow: 1;
  flex-shrink: 0;

  /* hack to make make it extremmely small on small screens */
  width: 1px;
  height: 100%;

  padding: unset;
  padding-left: 1px;
  padding-bottom: 3px;

  margin: unset;

  /* deal with application.css */
  border: unset;
  border-bottom: 1px solid var(--current-border-color);

  outline: none;

  transition: var(--border-transition);
}
.m-inline-search[focus-within] {
  --current-border-color: var(--border-focused-color);
}
.m-inline-search:focus-within {
  --current-border-color: var(--border-focused-color);
}
.m-inline-search__icon {
  color: var(--search-icon-color);

  flex-shrink: 0;

  width: 18px;
  height: 18px;

  padding-bottom: 2px;

  border-bottom: 1px solid var(--current-border-color);

  transition: var(--border-transition);
}
