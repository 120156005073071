
:root {
  --hover-background-color: var(--action-background-color);
  --hover-color: var(--secondary-text-color);
  --active-item-background-color: var(--action-color);
  --active-item-text-color: #FFFFFF;
  --active-hover-background-color: var(--action-hover-color);
  --options-text-color: var(--secondary-text-color);
}
.select-option_selectable:hover,
  .select-option_selectable.select-option_focus {
    color: var(--secondary-text-color);
    color: var(--hover-color);
    background-color: var(--action-background-color);
    background-color: var(--hover-background-color);
}
.select-option_sticky {
  box-shadow: 0 1px 4px rgba(129, 129, 129, 0.25);
}
.select-option {
  color: var(--secondary-text-color);
  color: var(--options-text-color);

  cursor: pointer;

  display: flex;

  flex-direction: row;
  align-items: center;

  box-sizing: border-box;
  min-height: 30px;

  padding: 3px var(--select-option-padding-side-val) 3px var(--select-option-indent);

  transition: height 500ms;
}
.select-option_large {
  min-height: 32px;
}
.select-option__text {
  line-height: 16px;
  text-overflow: ellipsis;

  white-space: unset;

  overflow: hidden;

  /* webkit-box not working in firefox, but in this case it is ok, it will not break layout */
  display: -webkit-box;  /* stylelint-disable-line value-no-vendor-prefix */

  /* stylelint-disable property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* stylelint-enable property-no-vendor-prefix */
}
.select-option_non_active {
  color: var(--gray-text-color);

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none
}
.select-option_non_active:hover,
  .select-option_non_active.select-option_focus {
    color: var(--gray-text-color);

    cursor: not-allowed;

    background-color: var(--gray-color);
}
.select-option_active {
  color: #FFFFFF;
  color: var(--active-item-text-color);
  background-color: var(--action-color);
  background-color: var(--active-item-background-color)
}
.select-option_active:hover,
  .select-option_active.select-option_focus {
    color: #FFFFFF;
    color: var(--active-item-text-color);
    background-color: var(--action-hover-color);
    background-color: var(--active-hover-background-color);
}
.select-option_optgroup {
  font-weight: var(--medium-font-weight);

  cursor: default;

  position: -webkit-sticky;

  position: sticky;
  top: 0;

  background-color: var(--background-color);
}
.select-option__icon {
  flex-shrink: 0;

  width: 14px;
  height: 14px;
}
.select-option__icon-after,
.select-option__icon {
  margin-right: 5px;
}
.select-option__help-icon {
  margin-left: 6px;
}
.select-option__icon-after-right {
  margin-left: auto;
  margin-right: 0;
}
.select-option .select-option__icon-after:first-of-type {
  margin-left: 4px;
}
.select-option_active .m-help__icon {
  color: #FFFFFF;
  color: var(--active-item-text-color);
}
.select-option_large-with-icon {
  --hover-background-color: #F9F9F9;
  --active-item-background-color: #EBF4FD;
  --active-hover-background-color: #EBF4FD;

  padding: 14px
}
.select-option_large-with-icon .select-option__icon {
    width: 32px;
    height: 32px;

    margin-right: 12px;
}
.select-option_large-with-icon .select-option__text {
    font-size: 14px;
    font-weight: 500;

    color: #242424;
    line-height: 16px;
}
.select-option_large-with-icon .select-option__text-main {
    display: flex;
    align-items: baseline;
}
.select-option_large-with-icon .select-option__description {
    font-size: 12px;
    font-weight: 400;

    color: #696969;
    line-height: 14px;

    margin-top: 4px;
}
.select-option_large-with-icon .select-option__label {
    font-size: 10px;
    font-weight: 500;

    line-height: 11px;

    margin-left: 4px;

    background: linear-gradient(235.1deg, #4965FB -3.88%, #FF3D67 99.79%);
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* stylelint-enable property-no-vendor-prefix */
}
