
.date-range-picker__label label {
  font-family: var(--main-font-family);
  font-size: var(--main-font-size);
  font-weight: var(--medium-font-weight);

  color: var(--text-color);
  line-height: var(--main-line-height);

  white-space: nowrap;

  display: inline-block;

  padding: 0;

  margin-bottom: 8px;

  -webkit-user-select: none;

     -moz-user-select: none;

          user-select: none;
}
.date-range-picker__text input:-moz-read-only {
  cursor: not-allowed;
  background-color: var(--background-color);
}
.date-range-picker__text input:read-only {
  cursor: not-allowed;
  background-color: var(--background-color);
}
.date-range-picker__text.small {
  height: 28px;

  padding-top: 1px;
  padding-bottom: 1px;
}
.date-range-picker__text {
  overflow: hidden;

  cursor: pointer;

  display: flex;

  align-items: center;

  box-sizing: border-box;
  width: 182px;
  height: 32px;

  background-color: var(--background-color);

  border: solid 1px;

  border-radius: 6px;

  border-color: var(--border-color);

  transition: 250ms ease-in-out;
}
.date-range-picker__text_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.date-range-picker__text_small {
  height: 28px;
}
.date-range-picker__text.disabled,
.date-range-picker__text.disabled input {
  color: var(--gray-text-color);

  cursor: not-allowed;

  background-color: var(--gray-table-color);

  border: 1px solid var(--gray-table-color);
}
.date-range-picker__text.disabled input {
  width: 55px;
}
