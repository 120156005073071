
.m-title .popper {
  box-shadow: 0 0 10px rgba(134, 134, 134, 0.25);
}
.m-title .popper__wrapper {
  display: inline;
}
.m-title__content-wrapper {
  font-size: 12px;

  color: var(--secondary-text-color);

  display: flex;

  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 269px;
  min-width: 0;

  padding: 4px 8px;
}
.m-title__content-wrapper .m-title__row:not(:first-child) {
  margin-top: 4px;
}
