
.date-range-picker__input {
  display: flex;
  align-items: center;
}
.date-range-picker__text input,
.date-range-picker__text input[type='text'].date-range-picker__input-range-text {
  font-size: var(--main-font-size);

  color: var(--secondary-text-color);

  text-align: center;

  width: 60px;

  padding: 5px;

  margin-bottom: 0;

  border: none;

  border-radius: 6px;
}

/* for input with range key text */
.date-range-picker__text input[type='text'].date-range-picker__input-range-text {
  text-align: left;

  width: 140px;

  padding: 2px 0 2px 5px;
}
.date-range-picker__text .date-range-picker__placeholder input {
  width: 122px;
}
.date-range-picker__calendar-icon {
  color: var(--secondary-text-color);

  flex-shrink: 0;

  width: 13px;
  height: 13px;

  margin-left: 10px;
  margin-bottom: 2px;
  margin-right: 4px;
}
.date-range-picker__text input:focus {
  border: none;
  outline: none;
}
