
.m-table-cell-container {
  --current-cell-width: 'auto';

  color: var(--text-main-color);
  vertical-align: top;
}
.m-table-cell-container_right-border {
  border-right: 1px solid var(--border-color);
}
.m-table-cell-container_column-sticked.m-table-cell-container_right-border {
  border-right-color: var(--background-color);
}
.m-table-cell-container_sticky-column {
  position: -webkit-sticky;
  position: sticky;
  left: var(--sticky-left-offset);
  z-index: 19;

  background: var(--row-cell-background);
}
.m-table-cell-container_last-nested-row-cell {
  border-bottom: 1px solid var(--border-color);
}
.m-table-cell-container_sticky-column::after {
  content: '';

  position: absolute;
  top: 0;
  right: -30px;

  width: 30px;
  height: 100%;

  box-shadow: inset 31px 0 18px -20px var(--sticky-cell-vertical-shadow-color);

  opacity: 0;

  transition: opacity 100ms;

  pointer-events: none;
}
.m-table-cell-container_column-sticked::after {
  opacity: 1;
}
.m-table-cell-container_right > * {
  text-align: right;

  /* this is to align M-Link (display: flex) */
  justify-content: flex-end;
}
.m-table-cell-container_center > * {
  text-align: center;

  /* this is to align M-Link (display: flex) */
  justify-content: center;
}
.m-table-cell-container_left > * {
  text-align: left;

  /* this is to align M-Link (display: flex) */
  justify-content: flex-start;
}
.m-table-cell-container_is-first.m-table-cell-container > .m-table-cell {
  padding-left: 14px;
}
.m-table-cell-container_is-last.m-table-cell-container > .m-table-cell {
  padding-right: 14px;
}
.m-table-cell-container_right.m-table-cell-container_sortable > .m-table-cell {
  padding-right: 19px;
}
.m-table-cell-container_is-last.m-table-cell-container_right.m-table-cell-container_sortable .m-table-cell,
.m-table-cell-container_right.m-table-cell-container_sortable.m-table-cell-container_right-border > .m-table-cell {
  padding-right: 27px;
}

